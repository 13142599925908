import React from "react";
import { NavLink } from "react-router-dom";
import "./Menu.css";
import menubg from "../../static/img/menuImage.png";
import sysiotLogo from "../../static/logos/sysiotlogo.png";

let menuBackground = {
  backgroundImage: `url(${menubg})`,
};
const Menu = () => {
  return (
    <>
      <div className="container-fluid  menu" style={menuBackground}>
        <div className="container">
          <div className="row">
            <nav className="navbar navbar-expand-lg  ">
              <div className="container-fluid">
                <NavLink className="navbar-brand" to="/">
                  <img src={sysiotLogo} alt="" id="sysiot_logo" />
                </NavLink>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 t">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Services
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <NavLink className="dropdown-item" to="/web-services">
                            Web Services
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/objets-connecté"
                          >
                            Objets Connectés (IoT)
                          </NavLink>
                        </li>

                        <li>
                          <NavLink className="dropdown-item" to="/embarqué">
                            Système Embarqué
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to="/développement-logiciels"
                          >
                            Développement de logiciels
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/contact">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <div className="row menu_header">
            <h1 className="text-center ">
              <span id="menu_para">Développement </span>
              de Produits
              <span id="menu_para"> Innovants</span> <br />{" "}
              <span id="menu_para"> Sites Web</span>,
              <span id="menu_para"> solutions IoT</span>,<br />
              <span id="menu_para"> Systèmes Embarqués</span> &{" "}
              <span id="menu_para"> logiciels</span>
            </h1>
          </div>
        </div>
      </div>

      {/* <img src={menubg} alt="" /> */}
    </>
  );
};

export default Menu;
