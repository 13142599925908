import { useState } from "react";
import axios from "axios";
import "./Contact.css";

const Contact = () => {
  const message_url = "https://sysiot.fr:1944/contacts/";
  const [send_notification, Setsend_notification] = useState("");
  const onSend = (event: any) => {
    event.preventDefault();

    let name = event.target[0].value;
    let email = event.target[1].value;
    let phone = event.target[2].value;
    let company = event.target[3].value;
    let subject = event.target[4].value;
    let message = event.target[5].value;

    axios
      .post(message_url, {
        name: name,
        email: email,
        phone: phone,
        company: company,
        subject: subject,
        message: message,
      })
      .then((resp) => {
        Setsend_notification(
          ` Merci ${resp.data.name} pour votre message, nous vous répondrons bientôt!`
        );
      })
      .catch((err) => {
        Setsend_notification(
          `Une erreur s'est produite lors de l'envoi de votre message, veuillez réessayer plus tard ou contactez-nous au contact@sysiot.fr`
        );
      });

    event.currentTarget.classList.toggle("send");
  };

  return (
    <>
      {" "}
      <div className="container  contact_us">
        <div className="row contact_header">
          <h2 className="text-center">Nous Contacter</h2>
        </div>
        <div className="row  justify-content-center contact-form">
          <div className="col col-lg-8 ">
            <form onSubmit={onSend}>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Nom *"
                required
              />{" "}
              <input
                type="email"
                name="email"
                id="name"
                placeholder="E-mail *"
                required
              />
              <br />
              <input
                type="text"
                name="phone"
                id="name"
                placeholder="Telephone *"
                required
              />{" "}
              <input
                type="text"
                name="company"
                id="name"
                placeholder="Societé *"
                required
              />
              <br />
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Sujet *"
              />
              <br />
              <textarea
                name="message"
                id="message"
                placeholder="Message *"
                required
              ></textarea>
              <input type="submit" name="" id="send_message" value="Envoyer" />
            </form>
            <p className="text-center">{send_notification}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
