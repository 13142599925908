import "./Web.css";
const WebService = () => {
  return (
    <>
      <div className="container web">
        <div className="row web-service-header">
          <h2 className="text-center">Web Services </h2>
        </div>
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 web-services">
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title">Web Designing</h5>
                <ul className="card-text">
                  <li>
                    Une fois votre projet prêt, nous vous enverrons un
                    questionnaire sur le site Web afin que nous puissions
                    comprendre vos goûts et vos objectifs. Votre réponse guidera
                    la première conception que nous vous soumettrons
                  </li>
                  <li>
                    Sur la base de vos commentaires, nous développons le contenu
                    et la conception du site Web.
                  </li>
                  <li>
                    Nous créons des sites Web réactifs qui fonctionnent aussi
                    bien sur les ordinateurs de bureau, les tablettes ou les
                    smartphones.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title">Maintenance du Site</h5>
                <ul className="card-text">
                  <li>Modification et ajout de contenu du site Web</li>
                  <li>Ajouter/supprimer des pages</li>
                  <li>
                    Surveiller votre site pour vous assurer qu'il fonctionne
                    normalement
                  </li>
                  <li>Restructurer le contenu de votre site</li>
                  <li>Maintenir votre site Web à jour</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row web-services last">
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title">Promotion Web</h5>
                <ul className="card-text">
                  <li>
                    Optimisation du moteur de recherche pour meilleure
                    visibilité de votre site Web sur Internet grâce aux moteurs
                    de recherche (google, yahoo, etc.)
                  </li>
                  <li>
                    Meilleur classement dans les moteurs de recherche ou
                    positionnement de votre site Web dans les moteurs de
                    recherche (Google, Yahoo, etc.)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <h5 className="card-title">Domaine & Hébergement</h5>
                <ul className="card-text">
                  <li>
                    Aider à choisir les meilleurs services pour votre nom de
                    domaine
                  </li>
                  <li>Proposer un service d'hébergement adapté à votre site</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebService;
