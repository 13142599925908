import "./Footer.css";
import { NavLink } from "react-router-dom";
import linkedIn from "../../static/logos/LinkedIn.png";
import instagram from "../../static/logos/Instagram.png";
import facebook from "../../static/logos/Facebook.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  let year = new Date().getFullYear();
  return (
    <>
      <div className="container">
        {" "}
        <div className="row " id="footer_services">
          <div className="col-7 ">
            <h3>Services</h3>
            <li>
              <NavLink to="/web-services">Web Services</NavLink>{" "}
            </li>
            <li>
              {" "}
              <NavLink to="/objets-connecté">Objets Connectés (IoT)</NavLink>
            </li>
            <li>
              <NavLink to="/embarqué">Système Embarqué</NavLink>
            </li>
            <li>
              <NavLink to="/développement-logiciels">
                Développement de logiciels{" "}
              </NavLink>
            </li>
          </div>

          {/* <div className="col ">
            <div id="footer_contact">
              <h3>Contact</h3>
              <li>
                <FontAwesomeIcon icon={faEnvelope} /> contact@sysiot.fr
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} /> +33 7 62 14 99 71
              </li>
            </div>
          </div> */}
        </div>
        {/* <div className="row">
          <div className="col-12 text-center" id="social_channels">
            <img src={linkedIn} alt="" />
            <img src={instagram} alt="" />
            <img src={facebook} alt="" />
          </div>
        </div> */}
        <div className="row contact">
          <div className="col-12 text-center   ">
            <p>
              <FontAwesomeIcon icon={faPhone} /> +33 7 62 14 99 71
            </p>
            <p>
              {" "}
              <FontAwesomeIcon icon={faEnvelope} /> contact@sysiot.fr
            </p>
          </div>
        </div>
        <div className="row text-center" id="copy_right">
          <p> &copy;{year} SysIoT - Tous droits réservés</p>
        </div>{" "}
      </div>
    </>
  );
};

export default Footer;
