import "./Iot.css";

const Iot = () => {
  return (
    <>
      <div className="container iot">
        <div className="row iot-header">
          <h2 className="text-center">Services des objets connectés (IoT)</h2>
        </div>
        <div className="row iot-services">
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">
                  {" "}
                  Développement, intégration et exploitation des systèmes IoT
                  dont votre entreprise a besoin
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">
                  Un accompagnement professionnel pour l'ensemble de votre
                  projet IoT
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row iot-services last">
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">
                  Visualisation et analyse de données provenant de diverses
                  sources
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">
                  Déploiement d'une solution IoT adaptée à vos besoins
                  individuels
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Iot;
