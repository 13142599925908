import "./Home.css";
import web from "../../static/logos/Web.png";
import iot from "../../static/logos/IoT.png";
import embedded from "../../static/logos/Embedded.png";
import software from "../../static/logos/Software.png";
import fondamenteaux from "../../static/img/fondamenteaux.png";
import { NavLink } from "react-router-dom";

let fondamenteauxBg = {
  backgroundImage: `url(${fondamenteaux})`,
};

const Home = () => {
  return (
    <>
      <div className="container">
        <div className="row services_title">
          <h1 className="text-center">Nos Services</h1>
        </div>
        <div className="row justify-content-center text-center services_sections">
          <div className="col  col-lg-4 ">
            <NavLink to="/web-services">
              {" "}
              <div className="services_card">
                <div className="services_card_img">
                  <img src={web} alt="" />
                </div>
                <div className="services_card_title">
                  <h2>
                    Web <br /> Services
                  </h2>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col col-lg-4 ">
            <NavLink to="/objets-connecté">
              <div className="services_card">
                <div className="services_card_img" id="iot_icon">
                  <img src={iot} alt="" id="iot_icon" />
                </div>
                <div className="services_card_title">
                  <h2>
                    Objets <br /> Connectés (IoT)
                  </h2>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="row justify-content-center text-center services_sections">
          <div className="col col-lg-4  ">
            <NavLink to="/embarqué">
              {" "}
              <div className="services_card">
                <div className="services_card_img">
                  <img src={embedded} alt="" />
                </div>
                <div className="services_card_title">
                  <h2>
                    Systèmes
                    <br /> Embarqués
                  </h2>
                </div>
              </div>
            </NavLink>
          </div>
          <div className="col col-lg-4  ">
            <NavLink to="/développement-logiciels">
              {" "}
              <div className="services_card">
                <div className="services_card_img">
                  <img src={software} alt="" />
                </div>
                <div className="services_card_title">
                  <h2>
                    Développement <br /> de Logiciels
                  </h2>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="container-fluid  fondamentaux" style={fondamenteauxBg}>
        <div className="container ">
          <div className="row text-center  fondamentaux_title">
            <h2>Nos Fondamentaux</h2>
          </div>
          <div className="row justify-content-center fondamentaux_body ">
            <div className="col-6">
              <h2 className="">Réactivité et Flexibilité</h2>
              <p>Rapidité à vous répondre</p>
            </div>
            <div className="col col-lg-2">
              <h2>Fiabilité</h2>
              Des projets toujours livrés à temps
            </div>
          </div>

          <div className="row justify-content-center fondamentaux_body fondamentaux_body_last  ">
            <div className="col-6">
              <h2 className="">Transparence</h2>
              <p>
                Des processus de workflow ouverts et actualisés à chaque étape
                du projet
              </p>
            </div>
            <div className="col col-lg-2">
              <h2>Proactivité</h2>
              <p>
                Une expertise reconnue dans l’élaboration des solutions
                innovantes
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
