import "./Software.css";

const Software = () => {
  return (
    <>
      <div className="container software">
        <div className="row software-header">
          <h2 className="text-center">Développement de logiciels</h2>
        </div>
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 software-services">
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">Logiciel de gestion des stocks</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">Logiciel de gestion clinique</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">Logiciel de gestion de projet</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 software-services last">
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">Logiciel de compte/facturation</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">Logiciel de gestion hospitalière</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <div className="card-body">
                <p className="card-text">Logiciel de gestion scolaire</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Software;
