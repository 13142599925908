import "./App.css";
import Home from "./components/Home/Home";
import Menu from "./components/Home/Menu";
import Footer from "./components/Home/Footer";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import Embedded from "./components/Embedded/Embedded";
import Iot from "./components/Iot/Iot";
import Software from "./components/Software/Software";
import WebService from "./components/Web/Web";
import Contact from "./components/Contact/Contact";

function App() {
  return (
    <div className="App">
      <header>
        <Menu></Menu>
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="embarqué" element={<Embedded />} />
          <Route path="objets-connecté" element={<Iot />} />
          <Route path="développement-logiciels" element={<Software />} />
          <Route path="web-services" element={<WebService />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
      </main>
      <footer className="container-fluid">
        <Footer></Footer>
      </footer>
    </div>
  );
}

export default App;
