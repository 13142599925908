import "./Embedded.css";

const Embedded = () => {
  return (
    <>
      <div className="container embedded">
        <div className="row embedded-header">
          <h2 className="text-center">Services Systèmes Embarqués</h2>
        </div>
        <div className="row">
          <p className="text-center">En cours de construction</p>
        </div>
      </div>
    </>
  );
};

export default Embedded;
